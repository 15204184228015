.grid {
    display: grid;
    grid-gap: 15px;
  }
  .grid-inside{
      padding-right: 10px !important;
      padding-left: 10px !important;
  }
  .grid-inside > article {
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  
  .grid-inside > article img {
    max-width: 100%;
  }
  
  .grid .text {
    padding: 20px;
  }