
  .bodycontain{
    font-family: Lato, sans-serif;
    color: #131313;
    cursor: default;
    user-select: none;
    /* flex-direction: column; */
    min-height: 100vh;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    gap: 1em;
  }
  .conHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .conHeader img {
    height: 100px;
  }
  .conHeader h1 {
    text-align: center;
    color: black;
    font-weight: 700;
    text-transform: uppercase;
  }
  .conMain {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1em;
  }
  .table_wrapper{
    flex:1;
  }
  .table_wrapper:nth-child(1){
    text-align:center;
  }
  
  @media only screen and (max-width: 800px) {
    .conMain{
      flex-direction: column !important;
      align-items: center;
    }
  }
  #table_medals,
  #table_noc {
    text-align: center;
    border-collapse: collapse;
    display: inline-block;
    max-height: 600px;
    overflow-y: auto;
  /*   resize: vertical; */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  #table_noc{
    height: unset;
  }
  
  #table_medals::-webkit-scrollbar {
    display: none;
  }
  
  #table_noc thead,
  #table_medals thead {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0 2px #c4c4c4;
  }
  #table_medals tfoot {
    position: sticky;
    bottom: 0;
    background-color: white;
    box-shadow: 0 -2px #c4c4c4;
  }
  /* rows */
  
  #table_noc tr,
  #table_medals tr {
    border-bottom: 1px solid #c4c4c4;
    
  }
  
  #table_medals tr {
    cursor: pointer;
  }
  
  #table_noc thead tr,
  #table_medals thead tr {
    border-bottom-width: 2px;
  }
  
  #table_noc tbody tr,
  #table_medals tbody tr {
    background-color: #f1f1f1;
  }
  
  #table_noc tbody tr:nth-child(odd),
  #table_medals tbody tr:nth-child(odd) {
    background-color: #f8f8f8;
  }
  
  #table_noc tbody tr:hover,
  #table_medals tbody tr:hover,
  #table_medals tbody tr.active { 
    background-color: #d6d6d6;
  }
  
  /* cells */
  
  #table_noc tr > *,
  #table_medals tr > * {
    padding: 0.5em 0.5em;
  }
  
  #table_noc tr td.sport_name,
  #table_noc tr th.noc_name,
  #table_medals tr th#medal_team,
  #table_medals tr td.medal_team {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    padding: 12px;
  }
  
  #table_noc thead tr i,
  #table_medals thead tr i {
    font-size: 1.5em;
  }
  
  #table_noc tr th.noc_gold,
  #table_medals tr th#medal_gold i {
    color: #c9b037;
  }
  
  #table_noc tr th.noc_silver i,
  #table_medals tr th#medal_silver i {
    color: #b4b4b4;
  }
  
  #table_noc tr th.noc_bronze,
  #table_medals tr th#medal_bronze i {
    color: #ad8a56;
  }
  
  #table_noc .sport_img span,
  #table_noc .noc_flag span {
    display: block;
    margin: 0 auto;
    width: 1.2em;
    height: 1.2em;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  #table_medals .team_flag {
    display: block;
    float: left;
    margin-right: 0.5em;
    width: 1.2em;
    height: 1.2em;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
  }
  
  #table_medals th.sort {
    cursor: pointer;
  }
  
  #table_medals th.sort:after {
    content: "\f0dc";
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
  
    margin-left: 0.5em;
    color: #c4c4c4;
  }
  
  #table_noc th,
  #table_medals th {
      font-size:20px;
    font-weight: 700;
    padding: 15px;
  }
  
  #table_noc th.noc_name,
  #table_medals th.sort.active {
    font-weight: 700;
  }
  #table_medals th.sort.active:after {
    color: unset;
  }
  
  #table_medals .table_nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2em;
  }
  #table_medals .table_nav i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    border-radius: 25%;
    transition: all 0.125s ease;
  }
  #table_medals tfoot td {
    padding: 0.2em;
  }
  #table_medals .table_nav i:hover {
    background-color: red;
    background-color: #131313;
    color: white;
    cursor: pointer;
  }
  #table_medals .table_nav i:active {
    transform: scale(0.8);
  }
  
     