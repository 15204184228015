/* Normal desktop :1200px. */
@media (min-width:1200px){
    .container{max-width:1140px;}
    .container-fluid{max-width:1640px;}
}

/* Normal desktop :992px. */
@media (min-width:992px){
    .widget-area{padding-left:30px;}
    .col-1-5{-ms-flex:0 0 20%;flex:0 0 20%;max-width:20%;}
    .col-2-5{-ms-flex:0 0 40%;flex:0 0 40%;max-width:40%;}
    .col-3-5{-ms-flex:0 0 60%;flex:0 0 60%;max-width:60%;}
    .col-4-5{-ms-flex:0 0 80%;flex:0 0 80%;max-width:80%;}
    .slicknav_nav{border-bottom:1px solid #f4f5f9;border-top:1px solid #f4f5f9;box-shadow:0px 0px 40px 0px rgba(0,0,0,0.05);}
    .entry-wraper,
    .entry-header.entry-header-style-1,
    .single-comment{max-width:800px;margin:0 auto;}
}

/* Tablet desktop :768px. */
@media (min-width:768px){
    .slicknav_nav li{display:block;max-width:720px;margin:0 auto;position:relative;}
}

/* small mobile :992px. */
@media (max-width:992px){
    .main-header,
    .header-style-1{height:88px;}
    .header-right{margin-right:48px;}
    .off-canvas-toggle-cover{top:30px}
    .main-header .logo{margin:0;position:absolute;left:50%;transform:translateX(-50%);}
    .comment-form .email{padding-left:0px;}
    .mb-md-30{margin-bottom:30px!important;}
    .mb-md-0{margin-bottom:0px!important;}
    .featured-slider-1 .featured-slider-1-arrow{display:none}
    .slide-fade-inner .post-thumb{position:relative;top:0;}
    .slide-fade-arrow-cover-2{top:50%;left:15px;}
    .post-content-overlay.layout-2{right:unset;left:50%;transform:translateX(-50%);min-width:80%;}
    .post-content-overlay.layout-2 .post-excerpt{display:none;}
    .post-content-overlay.layout-2 h2.post-title{font-size:20px;line-height:1.4;}
    .slide-fade-arrow-cover-2.layout-2{bottom:0;left:30px;top:unset;}
}

/* small mobile :768px. */
@media (max-width:767px){
    .comment-form .name{padding-right:0px;margin-bottom:1rem;}
}

/* Large Mobile :480px. */
@media (max-width:480px){
    .canvas-opened #sidebar-wrapper{width:375px;}
    .comments-area{padding:50px 8px;}
    .vertical-divider > [class*="col-"]:nth-child(n+2)::after{content:none;}
    .featured-slider-1 h2.post-title,
    .slide-fade h2.post-title{font-size:30px;line-height:1.4;}
    .text-sm-left{text-align:left!important;}
    .col-sm-6{width:50%;}
    .mb-sm-4{margin-bottom:1.5rem!important;}
    .p-sm-25{padding:25px!important}
    .mb-sm-3{margin-bottom:1rem!important;}
    .mt-sm-4{margin-top:1.5rem!important;}
    .mt-sm-3{margin-top:1rem!important;}
    .pl-0{padding-left:15px!important;}
    .col-xs-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
    .home-featured-1 .img-hover-slide{min-height:450px;}
    .preloader::before{font-size:3em}
    .loop-list-style-1 .post-thumb{margin-bottom:15px;}
    .featured-1 h2{font-size:30px;font-weight:900;}
    .entry-header .entry-title{font-size:2.2em;line-height:1.4;}
    .featured-slider-2-items .post-thumb .thumb-overlay{min-height:450px;}
    .featured-slider-2 .post-content-overlay .post-title{max-width:100%;}
    .featured-slider-2-nav{display:none}
    .loop-list-style-1 .post-meta-2{padding-left:15px;margin-bottom:15px;}
    .w-sm-100{width:100%!important;min-width:100%;}
    .entry-bottom{display:block;}
    .author-bio .author-image,
    .author-bio .author-image img{width:80px;height:91px;}
}

/*List 2 layout*/
@media (max-width:575px){
    .grid-sizer,
    .grid-item{width:100%;}
    .grid-line{display:none}
}
@media (min-width:576px) and (max-width:767px){
    .grid-sizer,
    .grid-item{width:50%;}
}
@media (min-width:768px){
    .grid-sizer,
    .grid-item{width:33.333%;}
}