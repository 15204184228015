

@media only screen and (max-width: 767px){
    .nav__list li{
        line-height: 1.2 !important;
    }
    
    .nav__list{
        margin: 0px !important;
    }
}