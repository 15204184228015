.image-27{
  display: inline-block;
      overflow: visible;
      width: 100%;
      padding: 0px;
      border-style: none;
      border-width: 1px;
      border-color: rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      background-color: #e7e7e7;
      box-shadow: 0 10px 10px 0 rgb(0 0 0 / 10%)
  }
  
  .div-block-57{
      margin-top: 12px;
      padding-right: 8px;
      padding-left: 8px;
  }
  
  .heading-23{
      color: #333;
      font-size: 16px;
      line-height: 100%;
      text-decoration: none;
      text-transform: uppercase;
  }
  
  .single-award{
      margin-bottom: 15px;
  }
  
  
  
                                 .date {
                                    position: relative;
                                  }
                                  
                                  .date div {
                                        /*visiblity: hidden; */
                                        
                                        display: none;
                                        position: absolute;
                                        top: 40px;
                                        /*left:100%;*/
                                        margin-top: -30px; /* approx adjustment for arrow */
                                        margin-left: 25px; /* approx adjustment for arrow */
                                      }
                                      
                                      .date a:hover + div {
                                              z-index: 1000000;
                                            cursor: pointer;
                                            display: block;
                                            /*visibility: visible; */
                                            width: 210px;
                                            line-height: 20px;
                                            padding: 8px;
                                            font-size: 12px;
                                            text-align: center;
                                            color: #000;
                                            background: rgb(255, 255, 255);
                                            border: 4px solid rgb(255, 255, 255);
                                            border-radius: 5px;
                                            text-shadow: rgba(0, 0, 0, 0.0980392) 1px 1px 1px;
                                            box-shadow: #333 -4px 4px 16px 2px;
                                            -webkit-transition: opacity 100ms ease-in;
                                            -o-transition: opacity 100ms ease-in;
                                            -moz-transition: opacity 100ms ease-in;
                                            transition: opacity 100ms ease-in;
                                            pointer-events: none;
                                          }
                                          
                                          .date div:after {
                                            content: "";
                                            position: absolute;
                                            width: 0;
                                            height: 0;
                                            border-width: 10px;
                                            border-style: solid;
                                            border-color: transparent #FFFFFF transparent transparent;
                                            top: 22px;
                                            left: -23px;
                                          }