   /* Card Styles */

   .card-sl {
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card-image img {
    max-height: 100%;
    max-width: 100%;
    border-radius: 8px 8px 0px 0;
}

.card-action {
    position: relative;
    float: right;
    margin-top: -25px;
    margin-right: 20px;
    z-index: 2;
    color: #E26D5C;
    background: #fff;
    border-radius: 100%;
    padding: 15px;
    font-size: 15px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
}

.card-action:hover {
    color: #fff;
    background: #E26D5C;
    -webkit-animation: pulse 1.5s infinite;
}

.card-heading {
    font-size: 18px;
    font-weight: bold;
    background: #fff;
    padding: 10px 15px;
}

.card-text {
    padding: 10px 15px;
    background: #fff;
    font-size: 14px;
    color: #636262;
}

.card-button {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    width: 100%;
    background-color: #1F487E;
    color: #fff;
    border-radius: 0 0 8px 8px;
}

.card-button:hover {
    text-decoration: none;
    background-color: #1D3461;
    color: #fff;

}


@-webkit-keyframes pulse {
    0% {
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }

    70% {
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -webkit-transform: scale(1);
        transform: scale(1);
        box-shadow: 0 0 0 50px rgba(90, 153, 212, 0);
    }

    100% {
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(90, 153, 212, 0);
    }
}




/*.container {
display: flex;
justify-content: flex-end;
position: relative;
float: right;
margin: 0 0 80px;
}*/

.tip {
position: absolute;
top: 50%;
right: calc(100% + 10px);
transform: translateY(-50%);
font-size: 10px;
text-transform: uppercase;
letter-spacing: 0.1em;
display: flex;
align-items: center;
}
.tip:after {
content: "";
width: 30px;
height: 1px;
background: rgba(44, 44, 44, 0.3);
margin: 0 0 0 8px;
}
@media only screen and (max-width: 600px) {
.tip {
display: none;
}
}

.trigger {
height: 40px;
line-height: 40px;
font-size: 14px;
text-align: center;
cursor: pointer;
transition: color 0.2s, border 0.2s;
}
.trigger a {
color: currentColor;
text-decoration: none;
transform: skew(10deg);
display: flex;
align-items: center;
justify-content: center;
}
.trigger i {
font-size: 14px;
line-height: 40px;
}
.trigger:hover {
color: #2c2c2c;
border-color: #2c2c2c !important;
}
.trigger.share-btn i {
margin: 0 5px 0 0;
}

.like, .share {
transform: skew(-10deg);
margin: 0 0 0 10px;
}
.like .trigger, .share .trigger {
width: 82px;
border: 1px solid rgba(44, 44, 44, 0.3);
}
.like i, .share i {
margin: 0 5px 0 0;
}

@media only screen and (min-width: 601px) {
.share {
display: none;
}
}

.share-window {
transform: skew(-10deg);
overflow: hidden;
}
@media only screen and (max-width: 600px) {
.share-window {
display: none;
}
}

.share-bar {
position: relative;
width: 82px;
height: 40px;
transition: width 0.4s ease 0.2s;
}
.share-bar:before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-width: 1px;
border-style: solid;
border-color: rgba(44, 44, 44, 0.3) rgba(44, 44, 44, 0.3) rgba(44, 44, 44, 0.3) transparent;
}
.share-bar:hover {
width: 247px;
transition: width 0.4s ease;
}
.share-bar:hover .trigger:nth-child(3) {
left: 82px;
transition: left 0.45s ease 0.06s, color 0.2s, border 0.2s;
}
.share-bar:hover .trigger:nth-child(4) {
left: 123px;
transition: left 0.45s ease 0.12s, color 0.2s, border 0.2s;
}
.share-bar:hover .trigger:nth-child(5) {
left: 164px;
transition: left 0.45s ease 0.18s, color 0.2s, border 0.2s;
}
.share-bar:hover .trigger:nth-child(6) {
left: 205px;
transition: left 0.45s ease 0.24s, color 0.2s, border 0.2s;
}
.share-bar .trigger {
position: absolute;
top: 0;
width: 42px;
border-width: 1px;
border-style: solid;
border-color: transparent transparent transparent rgba(44, 44, 44, 0.3);
transition: left 0s ease 0.45s, color 0.2s, border 0.2s;
}
.share-bar .trigger:nth-child(1) {
left: 0;
}
.share-bar .trigger:nth-child(2) {
left: 41px;
}
.share-bar .trigger:nth-child(3) {
left: 102px;
}
.share-bar .trigger:nth-child(4) {
left: 143px;
}
.share-bar .trigger:nth-child(5) {
left: 184px;
}
.share-bar .trigger:nth-child(6) {
left: 225px;
}


